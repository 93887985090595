import { useState, useEffect, useSearchParams } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import App from  './App'
const fromDomain = {
    'blue': 'buloo.iranet.shop',
    'line': 'myline.iranet.shop',
    'do': 'mydoping.iranet.shop',
    'main': 'main.iranet.shop',
    'mega': 'mega.iranet.shop',
    'pingoo': 'pingoo.iranet.shop',
  
  }
  const requestPay = {
    'blue': 'requestbl.php',
    'line': 'request.php',
    'do': 'requestdo.php',
    'main': 'requestmn.php',
    'mega': 'requestme.php',
    'pingoo': 'requestpo.php',
  
  }
  const queryParams = new URLSearchParams(window.location.search);
  const fromAt = queryParams.get('from') || 'do';
function BTN(props) {
    if (props.sendData === true) {
        return (

            <>
                <div className=" mb-3 d-flex justify-content-start">
                    <button className="btn btn-light  d-flex justify-content-between align-items-center" id='btn-next' disabled>
                     ارسال اطلاعات
                        <span class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        
                    </button>




                </div>
            </>
        )
    } else {
        return (

            <>

                <div className=" mb-3 d-flex justify-content-start">


                    <button className={`btn btn-light ${props.canSend === false ? 'disabled' : ''}   m-0 py-1 px-4`} id='btn-next' onClick={(e) => props.ChangeStep(e)}> {props.step === 1 ? 'ارسال کد': 'ورود' } </button>



                </div>
            </>
        )
    }



}


function Login() {
    const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string)
    function checkValue(e) {
        if (e.target.value.length >= 1) {
            if (!isNumeric(e.target.value)) {
                setErrorMessage('لطفا فقط عدد وارد کنید')
                setCanSend(false)
            } else {
                setErrorMessage('')
                setCanSend(true)
            }
            if (e.target.value.length > 11) {
                setErrorMessage('لطفا فقط 11 رقم وارد کنید')
                setCanSend(false)
            } else {
                if (errorMessage === 'لطفا فقط 11 رقم وارد کنید') {
                    if (!isNumeric(e.target.value)) {
                        setErrorMessage('لطفا فقط عدد وارد کنید')
                        setCanSend(false)
                    } else {
                        setErrorMessage('')

                    }

                }

            }
        } else {
            setErrorMessage('')
            setCanSend(false)
        }


    }
    const [sendData, setSending] = useState(false);
    const [canSend, setCanSend] = useState(false);
    const [step, setStep] = useState(1);
    const [code, setCode] = useState('');
    const [islogged,setlogged] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const cookies = new Cookies();
    const queryParams = new URLSearchParams(window.location.search);
  const userId = queryParams.get('userId')|| null;
    
    function ChangeStep(e) {
        e.preventDefault()
        if (step=== 1){
            if (phoneNumber.length >= 11 && isNumeric(phoneNumber)) {
                setSending(true)
                axios.post(`https://${fromDomain[fromAt]}/sendCode`, {
                    phonenumber: Number(phoneNumber),
                    userId: userId || null
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((result) => {
                    setSending(false)
                    if (result.data.success===true){
                        setStep(2)
                        setSending(false)
                    }else{
    
                        setSending(false)
                        setErrorMessage(result.data.message)
                    }
                }).catch((err) => {
    
                });
            }
            else {
                setErrorMessage('لطفا شماره تلفن را به درستی وارد کنید')
            }
        }else if (step==2){
            if (code.length >= 6 && isNumeric(code)) {
                setSending(true)
                axios.post(`https://${fromDomain[fromAt]}/setCode`, {
                    phonenumber: Number(phoneNumber),
                    userId: userId || null,
                    code:code
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((result) => {
                    setSending(false)
                    if (result.data.success===true){
                        setStep(2)
                       const date  = new Date(Date.now())
                        date.setMonth(date.getMonth() + 1)
                        cookies.set('phonenumber',phoneNumber,{path:'/',expires:date})
                        setSending(false)
                        setlogged(true)
                     
                    }else{
    
                        setSending(false)
                        setErrorMessage(result.data.message)
                    }
                }).catch((err) => {
    
                });
            }
            else {
                setErrorMessage('لطفا شماره تلفن را به درستی وارد کنید')
            }
        }
       
    }
    useEffect(() => {
        setErrorMessage('')
    }, [])

function checkValueCode(e){
    if (e.target.value.length >= 1) {
        if (!isNumeric(e.target.value)) {
            setErrorMessage('لطفا فقط عدد وارد کنید')
            setCanSend(false)
        } else {
            setErrorMessage('')
            setCanSend(true)
        }
        if (e.target.value.length > 6) {
            setErrorMessage('لطفا فقط 6 رقم وارد کنید')
            setCanSend(false)
        } else {
            if (errorMessage === 'لطفا فقط 6 رقم وارد کنید') {
                if (!isNumeric(e.target.value)) {
                    setErrorMessage('لطفا فقط عدد وارد کنید')
                    setCanSend(false)
                } else {
                    setErrorMessage('')

                }

            }

        }
    } else {
        setErrorMessage('')
        setCanSend(false)
    }

}

    function checkDataCode(e) {
        e.preventDefault()
        checkValueCode(e)
        setCode(e.target.value)
    }
    function checkData(e) {
        e.preventDefault()
        console.log(e.target.value.length > 1);
        checkValue(e)
        setPhoneNumber(e.target.value)

    }

if (islogged===true){
    return (
        <>
        <App loading={true}/>
        
        </>
                                );
}
    return (





        <>
            <div className="container ">
                <div className="w-100 d-flex form-content h-100 d-flex justify-content-center align-items-center">


                    <div className="row  d-flex loginpage-items d-flex rounded-3  justify-content-center align-items-center">


                        <div className="w-100  d-flex flex-column align-items-center">

                            <img src="https://xenitgame.com/wp-content/uploads/2022/03/ggg2.png" className="img-fluid w-75 image-login" alt="" />
                            <h2 className="text-white title-login  mb-4">ورود</h2>

                            <form className='w-100'>


                                <InputData step={step} checkData={checkData} code={code} checkDataCode={checkDataCode} phoneNumber={phoneNumber} />
                                <h6 className='text-danger'>{errorMessage}</h6>



                                <BTN  step={step} sendData={sendData} canSend={canSend} ChangeStep={ChangeStep} />

                            </form>


                        </div>

                    </div>

                </div>



            </div>





        </>
    );


}
function InputData(props) {
    if (props.step == 1) {
        return (

            <>

                <h6 className=" text-light">شماره تلفن</h6>
                <input className="input-login  text-light " value={props.phoneNumber} onChange={(e) => props.checkData(e)} placeholder='مثال 09130000000' />
            </>

        );
    } else {
        return (

            <>

                <h6 className=" text-light">کد دریافتی</h6>
                <input className="input-login  text-light " value={props.code} onChange={(e) => props.checkDataCode(e)} placeholder='مثال 123456' />
            </>

        );
    }


}

export default Login