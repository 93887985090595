
import './assets/bootstrap.rtl.min.css';
import './assets/App.css';
import { useState, useEffect, useSearchParams } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Login from './login';

import { Modal, Button, Form } from "react-bootstrap";
const fromDomain = {
  'amu':'sub.amunet.site',
  'blue': 'buloo.iranet.shop',
  'line': 'myline.iranet.shop',
  'do': 'mydoping.iranet.shop',
  'main': 'main.iranet.shop',
  'mega': 'mega.iranet.shop',
  'pingoo': 'pingoo.iranet.shop',

}
const supRobots = {
  'amu':'https://t.me/BuloopingSupport',
  'blue': 'https://t.me/BuloopingSupport',
  'line': 'https://t.me/myLineSupport',
  'do': 'https://t.me/MyDOPINGSupport',
  'main': 'https://t.me/MainOnline',
  'mega': 'https://t.me/MEGAPINGSUPPORT',
  'pingoo': 'https://t.me/PINGOOROBOT',

}
const requestPay = {
  'blue': 'requestbl.php',
  'line': 'request.php',
  'do': 'requestdo.php',
  'main': 'requestmn.php',
  'mega': 'requestme.php',
  'pingoo': 'requestpo.php',

}
const queryParams = new URLSearchParams(window.location.search);
var fromAt = queryParams.get('from');
function BTN(props) {
  if (props.sendData === true) {
    return (

      <>

        <div className="modal-footer d-flex justify-content-start">
          <button className="btn btn-purple " id='btn-next' disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ارسال
          </button>




        </div>
      </>
    )
  } else {
    console.log(props.step);
    return (

      <>

        <div className="modal-footer d-flex justify-content-start">


          <button className="btn btn-purple " id='btn-next' onClick={() => props.ChangeStep()}> {props.step === 3 ? 'خرید' : 'صفحه بعد'}</button>



        </div>
      </>
    )
  }



}


function ModalContentData(props) {
  console.log(props.data.data.data);
  if (props.data.data.data.length !== 0) {
    return (

      <ul className='modal-content-ars' >
        {props.data.data.data.map((element, index) => {
          console.log(index);
          console.log(props.activeobj);
          if (index === props.activeobj) {

            props.changeActive(index, element[0])
            return (

              <li className='active' key={element[0]} onClick={() => props.changeActive(index, element[0])}><a>{element[1]}  {element[2] ? `- ${element[2]} تومان` : ''}  {element[4] ? `- ${element[4]}ماه` : ''}  {element[3] ? ` - ${element[3]}GB` : ''} </a> </li>


            )
          } else {

            return (

              <li className='text-secondary' key={element[0]} onClick={() => props.changeActive(index, element[0])}><a>{element[1]}</a></li>


            )
          }



        })}
      </ul>





    );
  } else {

    return (

      <>

        <h5 className='text-light'>یافت نشد </h5>
      </>

    )
  }

}
function ModalContent(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [sendData, setSending] = useState(false);
  const [error, setError] = useState(props.error);
  const [activeobj, setObj] = useState(0);
  const [CatId, setCatId] = useState(null);
  const [ServerId, setServerId] = useState(0);
  const [planId, setPlanId] = useState(0);
  ;

  useEffect(() => {
    if (props.data.step <= 1) {
      setObj(0)
    }
  }, [props.data.step]);

  console.log(props.data.step);
  function changeActive(index, selectId) {


    if (props.data.step <= 1) {
      console.log(index);
      setObj(index)
      setCatId(selectId)
    } else if (props.data.step === 2) {

      setObj(index)
      setServerId(selectId)
    } else if (props.data.step === 3) {

      setObj(index)
      setPlanId(selectId)

    }



  }

  function ChangeStep() {
    console.log(CatId);
    console.log(error);
    const cookies = new Cookies();
    if (CatId === null) {
      setError(true)
      setErrorMessage('هیچ دسته بندی انتخاب نشده')
    } else {
      if (props.data.step <= 1) {
        setSending(true)
        setError(false)
        axios.get(`https://${fromDomain[fromAt]}/getServerCat?CatId=${CatId}`).then((result) => {
          setSending(false)
          if (result.data.success === true) {

            setObj(0)
            props.setModalShop({ data: result.data.data, step: 2, error: false })
          } else {
            setError(true)
            setErrorMessage(result.data.message)
          }

        }).catch(() => {

        });
      } else if (props.data.step === 2) {
        console.log(ServerId);
        setSending(true)
        setError(false)
        axios.get(`https://${fromDomain[fromAt]}/getserverPlan?CatId=${CatId}&ServerId=${ServerId}`).then((result) => {
          setSending(false)
          console.log(result.data);
          if (result.data.success === true) {

            props.setModalShop({ data: result.data.data, step: 3, error: false })
          } else {
            setError(true)
            setErrorMessage(result.data.message)
          }
        }).catch(() => {

        });

      } else if (props.data.step === 3) {
        setSending(true)
        setError(false)
        axios.post(`https://${fromDomain[fromAt]}/createOrder`, {
          serverId: ServerId,
          catId: CatId,
          planId: planId,
          phonenumber: Number(cookies.get('phonenumber'))

        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((result) => {
          if (result.data.success === true) {
            window.location = `https://xenitgame.com/onlinepay/${requestPay[fromAt]}?token=${result.data.orderId}&amount=${result.data.price}`

          }
        })
      }
    }

  }
  return (

    <>

      <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
        <div className="modal-dialog modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-light" id="exampleModalToggleLabel">{props.data.step <= 1 ? 'انتخاب دسته بندی' : props.data.step === 2 ? 'انتخاب سرور دلخواه' : 'انتخاب پلن '}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">

              <>
                <ModalContentData data={props} activeobj={activeobj} changeActive={changeActive} />

              </>

              <h6 className='text-danger'>

                {
                  error == true ? `  ${errorMessage}` : ''
                }
              </h6>
            </div>


            <BTN ChangeStep={ChangeStep} sendData={sendData} step={props.data.step} />
          </div>
        </div>
      </div>




    </>


  );

}


function App() {


  const [loading, setLoading] = useState(false)
  const [datamodal, setModalShop] = useState({ data: [], step: 1, error: false })
  const [show, setShow] = useState(false)
  const [success, setSuccess] = useState(false)
  const [serviceId, setServiceId] = useState(0)
  const [getTest, setGetTest] = useState(0)
  const [errorTest, setErrorMessageTest] = useState('')
  const [showTest, setShowTest] = useState(false)
  const [showService, setShOWServices] = useState(false)
  const [services, setServices] = useState(null)
  const queryParams = new URLSearchParams(window.location.search);
  if (fromAt === null) {
    fromAt = 'do'
  }



  const cookies = new Cookies();


  useEffect(() => {
    const buyService = queryParams.get('success') || null;
    console.log(buyService);
    if (buyService != null) {
      if (buyService === 'true') {
        const serviceIdGeted = queryParams.get('serviceId') || null;

        if (serviceIdGeted !== null) {
          setShow(true)
          setSuccess(true)

          setServiceId(serviceIdGeted)
        }

      } else {
        setShow(true)
        setSuccess(false)
      }
    }
  }, [queryParams.get('success')])

  const phoneNumber = cookies.get('phonenumber') || null
  console.log(phoneNumber);

  if (phoneNumber === null) {
    return (

      <Login />

    );
  }

  function getUserTest() {

    setLoading(true)
    axios(`https://${fromDomain[fromAt]}/getTest?token=${cookies.get('phonenumber')}`).then((result) => {
      setLoading(false)
      if (result.data.success === true) {
        setLoading(false)
        setGetTest(result.data.serviceId)
        setShowTest(true)
        setSuccess(true)


      } else {
        setLoading(false)
        setErrorMessageTest(result.data.message)
        setShowTest(true)
        setSuccess(false)
      }

    }).catch((err) => {
      setLoading(false)
      setErrorMessageTest('هنگام دریافت اطلاعات به مشکل خوردیم')
      setShowTest(true)
      setSuccess(false)
    });


  }

  function getUserService() {

    setLoading(true)
    axios(`https://${fromDomain[fromAt]}/getUserService?token=${Number(cookies.get('phonenumber'))}`).then((result) => {
      setLoading(false)
      if (result.data.success === true) {
        setLoading(false)
        setServices(result.data.data)
        setShOWServices(true)
        setErrorMessageTest('')
        setSuccess(true)


      } else {
        setLoading(false)
        setErrorMessageTest(result.data.message)
        setShOWServices(true)
        setSuccess(false)
      }

    }).catch((err) => {
      setLoading(false)
      setErrorMessageTest('هنگام دریافت اطلاعات به مشکل خوردیم')
      setShOWServices(true)
      setSuccess(false)
    });


  }
  function Logout(){
    console.log('salak');
    cookies.remove('phonenumber', { path: '/' });
    localStorage.removeItem('phonenumber');
  window.location.reload()
  }
  const clodeModal = () => {
    setShow(false)
    setErrorMessageTest('')
    setLoading(false)
    setShowTest(false)
    setShOWServices(false)

  };
  function showmodalShop() {

    axios.get(`https://${fromDomain[fromAt]}/getCategory`).then((result) => {

      console.log(result.data);
      setModalShop({ data: result.data.data, step: 1, error: false })
    }).catch(() => {

    });

  }



  return (

    <>
      {loading === true ? <Loading /> : ''}
      <header className="py-3 mb-3">
        <div className="container ">
          <div className="header-title justify-content-between  d-flex  justify-content-center   align-items-center ">
            <h6 className="text-light "><a className='text-light' href='' onClick={()=>Logout() }>خروج</a></h6>
            <img src="https://cdn.discordapp.com/attachments/930782153220948029/1267442326238793818/activedddnet.png?ex=66a8cd26&is=66a77ba6&hm=2a5f01addb9f188f23806f03a36701bb708a8095dfa765caff50f8f28ade3513&" className='img-fluid' alt="" />
          </div>
        </div>



      </header>


      <section className="py-4 ">

        <div className="container d-flex align-content-center justify-content-center">


         
        

            <div className=" mb-3 col-12 test-content">
              <div className="card rounded-3" >
                <a onClick={() => getUserTest()}>
                  <img src="assets/image/test.jpg" className="card-img-top " alt="..." />
                  <div className="card-body p-0 d-flex justify-content-center">

                    <a href="#" className="btn btn-custom w-100 rounded-0 overflow-hidden"></a>
                  </div>
                </a>
              </div>
            </div>
       

         
        </div>

      </section>






      <ModalContent data={datamodal} setModalShop={setModalShop} />




      <Modal show={showService} >
        <Modal.Header >
          <Modal.Title className='text-light' >نتیجه</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <ModalServices success={success} Services={services} errorMessage={errorTest} />
          </>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-start'>
          <Button variant="light" onClick={clodeModal} >بستن</Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showTest} >
        <Modal.Header >
          <Modal.Title className='text-light' >نتیجه</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <ModalTest success={success} serviceId={getTest} errorMessage={errorTest} />
          </>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-start'>
          <Button variant="light" onClick={clodeModal} >بستن</Button>
        </Modal.Footer>
      </Modal>






      <Modal show={show} >
        <Modal.Header >
          <Modal.Title className='text-light' >نتیجه</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <ContentService success={success} serviceId={serviceId} />
          </>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-start'>
          <Button variant="light" onClick={clodeModal} >بستن</Button>
        </Modal.Footer>
      </Modal>
    </>


  );
}
function ModalServices(props) {
  if (props.success === true) {
    return (

      <>
        <div className='d-flex flex-column w-100 justify-content-center align-items-center'>
          <ul className='modal-content-ars  w-100'>
            {props.Services.map((element, index) => {
              return (

                <li className='active w-100' ><a className='w-100 d-flex justify-content-center' href={`https://${fromDomain[fromAt]}/kaiser?token=${element[0]}`}>      <span>{element[1]}</span></a>  </li>
              );
            })}
          </ul>
        </div>
      </>

    );
  } else {
    return (

      <>
        <div className='w-100 mb-3 d-flex justify-content-center' >
          <img src="https://www.freeiconspng.com/uploads/error-icon-4.png" className='w-25   img-fluid'
            alt="Free High quality Error Icon" />
        </div>
        <h6 className='text-danger text-center'>دریافت سرویس ها مشکلی پیش آمد</h6>
        <p className='mt-4 text-center text-muted'
        >{props.errorMessage}
        </p>

      </>

    );
  }
}
function Loading() {
  return (

    <>

      <div className='w-100 loading-parent d-flex flex-column justify-content-center align-items-center'>
        <h3 className='text-white-50'>
          درحال دریافت اطلاعات
        </h3>
        <div class="spinner-grow text-white-50" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>

      </div>
    </>

  );
}

function ModalTest(props) {
  if (props.success === true) {
    return (

      <>
        <div className='d-flex flex-column justify-content-center align-items-center'>

          <div className='w-100 mb-3 d-flex justify-content-center' >
            <img src="https://www.freeiconspng.com/uploads/accept-tick-icon-12.png" className='w-25   img-fluid' alt="accept tick icon" />
          </div>
          <h6 className='text-success text-center'>دریافت تست موفقیت آمیز بود</h6>
          <p className='mt-4 text-center text-muted'> ! کاربر گرامی
            <br />
            سرویس شما با موفقیت ایجاد شد برای دریافت روی دکمه دریافت کلیک کنید
          </p>
          <a className='btn btn-success text-center align-self-center' href={`https://${fromDomain[fromAt]}/kaiser?token=${props.serviceId}`}>دریافت</a>
        </div>
      </>

    );
  } else {
    return (

      <>
        <div className='w-100 mb-3 d-flex justify-content-center' >
          <img src="https://www.freeiconspng.com/uploads/error-icon-4.png" className='w-25   img-fluid'
            alt="Free High quality Error Icon" />
        </div>
        <h6 className='text-danger text-center'>دریافت تست مشکلی پیش آمد</h6>
        <p className='mt-4 text-center text-muted'
        >{props.errorMessage}
        </p>

      </>

    );
  }

}
function ContentService(props) {
  if (props.success === false) {
    return (

      <>
        <div className='w-100 mb-3 d-flex justify-content-center' >
          <img src="https://www.freeiconspng.com/uploads/error-icon-4.png" className='w-25   img-fluid'
            alt="Free High quality Error Icon" />
        </div>
        <h6 className='text-danger text-center'>هنگام پرداخت مشکلی پیش آمد</h6>
        <p className='mt-4 text-center text-muted'>متاسفانه پرداخت موفقیت آمیز نبود درصورت کسر وجه با پشتیبانی در تماس باشید</p>

      </>

    );
  } else {
    return (

      <>
        <div className='d-flex flex-column justify-content-center align-items-center'>

          <div className='w-100 mb-3 d-flex justify-content-center' >
            <img src="https://www.freeiconspng.com/uploads/accept-tick-icon-12.png" className='w-25   img-fluid' alt="accept tick icon" />
          </div>
          <h6 className='text-success text-center'>پرداخت موفقیت آمیز بود</h6>
          <p className='mt-4 text-center text-muted'> ! کاربر گرامی
            <br />
            سرویس شما با موفقیت ایجاد شد برای دریافت روی دکمه دریافت کلیک کنید
          </p>
          <a className='btn btn-success text-center align-self-center' href={`https://${fromDomain[fromAt]}/kaiser?token=${props.serviceId}`}>دریافت</a>
        </div>
      </>

    );
  }

}
export default App;
