import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Index from './App';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes ,Redirect,Navigate } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
function App (){
  
  return (
<BrowserRouter>
<Routes>
  
<Route exact path="/ping" element={ <Index />}>
 
</Route>






</Routes>

</BrowserRouter>
   


  );
 

}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
